<template>
  <div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="width: 350px">
        <q-toolbar class="bg-indigo-1">
          <q-avatar>
            <q-icon name="manage_search" size="md"/>
          </q-avatar>

          <q-toolbar-title><span class="text-weight-bold">보고싶은 메뉴를 선택하세요.</span></q-toolbar-title>

          <!-- <q-btn flat round dense icon="close" @click="cancel" /> -->
        </q-toolbar>
        <q-list class="listArea" bordered separator>
          <q-item tag="label" 
            clickable 
            v-ripple
            :active="link === 'detail'"
            @click="link = 'detail'"
            active-class="menu-link"
          >
            <q-item-section avatar top>
              <q-radio v-model="pick" val="detail" active-class="bg-teal-1 text-grey-8" />
            </q-item-section>
            <q-item-section avatar>
              <q-icon name="construction" class="text-indigo-9"/>
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-indigo-9 labelArea"><b>설비상세</b></q-item-label>
            </q-item-section>
          </q-item>
          <q-item 
            tag="label" 
            clickable 
            v-ripple
            :active="link === 'spec'"
            @click="link = 'spec'"
            active-class="menu-link"
          >
            <q-item-section avatar top>
              <q-radio v-model="pick" val="spec" active-class="bg-teal-1 text-grey-8" />
            </q-item-section>
            <q-item-section avatar>
              <q-icon name="account_tree" class="text-indigo-9"/>
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-indigo-9 labelArea"><b>설비사양</b></q-item-label>
            </q-item-section>
          </q-item>
          <q-item 
            tag="label" 
            clickable 
            v-ripple
            :active="link === 'result'"
            @click="link = 'result'"
            active-class="menu-link"
          >
            <q-item-section avatar top>
              <q-radio v-model="pick" val="result" active-class="bg-teal-1 text-grey-8" />
            </q-item-section>
            <q-item-section avatar>
              <q-icon name="add_moderator" class="text-indigo-9"/>
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-indigo-9 labelArea"><b>예방점검</b></q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-card-actions align="right" class="text-primary">
          <q-btn 
            rounded 
            size="sm" 
            icon="login" 
            style="widh:100%" 
            type="submit"
            label="이동" 
            @click="select" 
          />
          <q-btn 
            rounded 
            size="sm" 
            icon="close" 
            style="widh:100%" 
            label="취소" 
            @click="cancel" 
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'equipment-qr-select',
  data() {
    return {
      editable: true,
      prompt: true,
      equipmentCd: '',
      plantCd: '',
      pick: null, 
      link: ''
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      if (this.$route.query.equipmentCd) {
        this.equipmentCd = this.$route.query.equipmentCd
      }
      if (this.$route.query.plantCd) {
        this.plantCd = this.$route.query.plantCd
      }
    },
    select() {
      if (!this.pick) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '메뉴를 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.prompt = false;

      if (this.pick === 'detail') {
        this.$router.push("/mdm/fim/equipmentManage?equipmentCd=" + this.equipmentCd + "&plantCd=" + this.plantCd + "&tabName=equipmentInfo");
      } else if (this.pick === 'spec') {
        this.$router.push("/mdm/fim/equipmentManage?equipmentCd=" + this.equipmentCd + "&plantCd=" + this.plantCd + "&tabName=equipmentSpec");
      } else if (this.pick === 'result') {
        this.$router.push("/sop/mim/inspection/equipmentInspection?equipmentCd=" + this.equipmentCd + "&plantCd=" + this.plantCd);
      }
    },
    cancel() {
      this.prompt = false;

      this.$router.push("/");
    },
  }
};
</script>
<style>
.labelArea {
  font-size: 17px;
}
/* .master-link {
  color: white;
  background: #f1b6b6;
} */
.menu-link {
  color: white;
  background: #b0d3fc;
}
/* .prevent-link {
  color: white;
  background: #ffca7c;
} */
</style>